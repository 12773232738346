<template>
    <div class="top">
        <div style="justify-content: space-around">
            <div class="top_border">
                <dv-decoration-10 style="width:100%;height:5px;"/>
            </div>

            <dv-border-box-10 style="width:250px;height: 40px">
                <router-link class="menu_title" to="/data-report">
                    大数据中心
                </router-link>

            </dv-border-box-10>
            <dv-border-box-10 style="width:250px;height: 40px">
<!--                <router-link class="menu_title" to="/digital-factory">-->
<!--                    数字工厂-->
<!--                </router-link>-->
                <router-link class="menu_title" to="/">
                    数字工厂
                </router-link>
            </dv-border-box-10>
        </div>
        <div>
            <dv-decoration-8 style="width:30%;height:70px;"/>
            <h1 @click="jump" style="cursor: pointer;font-size: 28px" v-if="false">陕驿大数据中心-测试系统</h1>
            <h1 @click="jump" style="cursor: pointer;font-size: 28px" >演示系统,上线后显示名称</h1>
            <dv-decoration-8 :reverse="true" style="width:30%;height:70px;"/>
        </div>
        <div style="justify-content: space-around">
            <div class="top_border reverse">
                <dv-decoration-10 :reverse="true" style="width:100%;height:5px;"/>
            </div>
            <dv-border-box-10 style="width:250px;height: 40px">
                <router-link class="menu_title" to="/overseas-warehouse">
                    智慧海外仓
                </router-link>
            </dv-border-box-10>
            <dv-border-box-10 style="width:250px;height: 40px">
                <router-link class="menu_title" to="/trade">
                    全球开店
                </router-link>
            </dv-border-box-10>

        </div>
    </div>
</template>

<script>
export default {
	name: "topNav",
	methods: {
		jump() {
			this.$router.push('/')
		}
	}
}
</script>

<style scoped lang="less">
.top {
  width: 100%;
  height: 70px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;

  & > div {
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    overflow: hidden;

    .top_border {
      position: absolute;
      width: 100%;
      top: -1px;
      left: 4px;
    }

    .reverse {
      transform: rotate(180deg);
      right: 4px;
      left: auto;
    }

    .menu_title {
      padding: 10px;
      width: 250px;
      height: 40px;
      overflow: hidden;
      background-image: linear-gradient(to right,rgba(0,155,255,0.01),rgba(0,155,255,0.2),rgba(0,155,255,0.01));
      //background: rgba(0, 255, 255, 0.05);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      cursor: pointer;
      color: #fff;
      text-decoration: none;

      &.router-link-active {
        //background: rgba(0, 155, 255, 0.3) !important;
        background-image: linear-gradient(to right,rgba(0,255,255,0),rgba(0,255,255,0.6),rgba(0,255,255,0));
      }

      &:hover {
        //background: rgba(0, 155, 255, 0.3) !important;
        background-image: linear-gradient(to right,rgba(0,255,255,0),rgba(0,155,255,0.6),rgba(0,255,255,0));
      }

    }


  }
}
</style>
<style lang="less">
.my_popper {
  background: rgba(0, 155, 255, 0.8) !important;
  border: 1px solid rgba(0, 155, 255, 0.8) !important;
  padding: 10px 0 !important;


  .menu_box {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    color: white;

    & > div, .menu_item {
      padding: 10px;
      cursor: pointer;

      &:hover {
        background: rgba(0, 255, 255, 0.3) !important
      }
    }

    .menu_item {
      background: transparent !important;
    }
  }
}
</style>
