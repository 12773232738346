<template>
    <dv-full-screen-container class="bg">

            <TopNav></TopNav>
            <div style="height: 100%;color: #fff">
                <router-view/>
            </div>

    </dv-full-screen-container>
</template>
<script>
import TopNav from "_c/topNav";
export default {
	components:{TopNav}
}
</script>
<style lang="less" scoped>
@import './assets/scss/index.less';

</style>
