import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'index',
		component: () => import('../views/index/index')
	},
	{
		path: '/data-report',
		name: 'dataReport',
		redirect: '/data-report/data',
		component: () => import('../views/data-report/data-report'),
		children: [
			{
				path: '/data-report/data',
				name: 'dataReportData',
				//component: () => import('../views/data-report/data1.vue'),
				component: () => import('../views/data-report/dataTotal'),
			},
			{
				path: '/data-report/test',
				name: 'dataReportTest',
				//component: () => import('../views/data-report/test'),
				component: () => import('../views/data-report/dataTest'),
			},
			{
				path: '/data-report/center',
				name: 'dataReportCenter',
				//component: () => import('../views/data-report/center'),
				component: () => import('../views/data-report/dataStore'),
			}, {
				path: '/data-report/shop',
				name: 'dataReportCentershop',
				component: () => import('../views/data-report/shop'),
			}
		]
	},
	{
		path: '/digital-factory',
		name: 'digitalFactory',
		redirect: '/digital-factory/factory',
		component: () => import('../views/digital-factory/index'),
		children: [
			{
				path: '/digital-factory/factory',
				name: 'dataReportData88',
				component: () => import('../views/digital-factory/views/factory'),
			},
			{
				path: '/digital-factory/order',
				name: 'dataReportTest99',
				component: () => import('../views/digital-factory/views/order'),
			},
			{
				path: '/digital-factory/live',
				name: 'dataReportTest99live',
				component: () => import('../views/digital-factory/live'),
			},
			{
				path: '/digital-factory/communicate',
				name: 'dataReportTest99communicate',
				component: () => import('../views/digital-factory/communicate'),
			},
			{
				path: '/digital-factory/development',
				name: 'dataReportTest99development',
				component: () => import('../views/digital-factory/development'),
			},
			{
				path: '/digital-factory/trademarks',
				name: 'dataReportTest99trademarks',
				component: () => import('../views/digital-factory/trademarks'),
			},
			{
				path: '/digital-factory/taxt',
				name: 'dataReportTest99taxt',
				component: () => import('../views/digital-factory/taxt'),
			},

		]
	},
	{
		path: '/overseas-warehouse',
		name: 'overseasWarehouse',
		redirect: '/overseas-warehouse/country',
		component: () => import('../views/overseas-warehouse/index'),
		children: [
			{
				path: '/overseas-warehouse/country',
				name: 'datacountryData',
				component: () => import('../views/overseas-warehouse/country'),
			},
			{
				path: '/overseas-warehouse/sys',
				name: 'datasysTest',
				component: () => import('../views/data-report/data1'),
			},
			{
				path: '/overseas-warehouse/first',
				name: 'datasysTestfirst',
				component: () => import('../views/overseas-warehouse/first'),
			},
			{
				path: '/overseas-warehouse/wearhouse',
				name: 'datasysTestwearhouse',
				component: () => import('../views/overseas-warehouse/wearhouse'),
			},
			{
				path: '/overseas-warehouse/out',
				name: 'datasysTestOut',
				component: () => import('../views/overseas-warehouse/out'),
			},

		]
	},
	{
		path: '/trade',
		name: 'TradePage',
		component: () => import('../views/trade/index')
	},
	{
		path: '*',
		redirect: '/'
	},
]
const router = new VueRouter({
	routes
})

export default router
